<template>
  <div class="doc-card">
    <document-upload
      :on-click="onUploadDoc"
      :document="currentDoc"
      :entity-type="entityClass"
      :slim="slim"
      :read-only="readOnly"
    />
    <modal-document-upload
      :id="idModal"
      :entity-type="entityClass"
      :entity-id="entityId"
      :document-name="documentName"
      :document="currentDoc"
      :on-success="updateDoc"
    />
  </div>
</template>

<script>
import { DOCUMENT_CLASS } from '@/utils/interfaces';
import { isNotEmpty } from '@/utils/validators';

const DocumentUpload = () => import('@/components/documents/DocumentUpload.vue');
const ModalDocumentUpload = () => import('@/components/documents/ModalDocumentUpload.vue');

export default {
  name: 'DocumentItem',
  props: {
    entityId: String,
    entityClass: String,
    currentDoc: { ...DOCUMENT_CLASS },
    onSuccess: Function,
    slim: Boolean,
    readOnly: Boolean,
  },
  components: {
    DocumentUpload,
    ModalDocumentUpload,
  },
  data() {
    return {
      documentToUpload: {
        document: null,
        documentName: null,
        documentType: null,
      },
    };
  },
  created() {
  },
  computed: {
    documentName() {
      return this.$store.getters['documents/decodeDocumentName'](this.currentDoc);
    },
    idModal() {
      return `DocItem_${this.currentDoc.documentId}`;
    },
  },
  methods: {
    updateDoc(response) {
      console.log('updateDoc', response);
      if (isNotEmpty(this.onSuccess)) {
        this.onSuccess();
      }
    },
    onUploadDoc() {
      this.$bvModal.show(this.idModal);
    },
  },
};
</script>

<style scoped>

</style>
